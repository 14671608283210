var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 font-Noto-Sans job-list-page"},[_c('v-sheet',{attrs:{"color":"transparent"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"fw-500 heading-text d-flex justify-space-between flex-wrap align-center mt-5 mb-2"},[_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('h2',{staticClass:"font-24px mb-1 mr-7"},[_c('span',{staticClass:"fw-500"},[_vm._v("お知らせ管理")]),_c('span',{staticClass:"line"}),_c('span',{staticClass:"font-20px fw-400"},[_vm._v("お知らせ一覧")])]),_vm._l((_vm.tabs),function(tab){return _c('div',{key:tab.type,staticClass:"d-flex font-14px mr-7 align-center cursor-pointer",class:tab.selected ? 'text-red' : '',on:{"click":function($event){return _vm.filterTable(tab.type)}}},[_c('span',{staticClass:"d-inline-block"},[_vm._v(_vm._s(tab.text)+_vm._s(tab.showCount ? '(' + tab.count + ')' : ''))])])})],2),_c('div',{staticClass:"d-flex font-14px align-center"},[_c('v-btn',{staticClass:"white text-red border-red mr-4",attrs:{"min-width":"188px"},on:{"click":function($event){_vm.toggleSearch = !_vm.toggleSearch
                _vm.setChangedInputType('search')}}},[_vm._v("詳細条件検索")]),_c('v-btn',{staticClass:"btn-pink white--text",attrs:{"min-width":"188px"},on:{"click":function($event){return _vm.$router.push({ name: 'NotificationsCreate' })}}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-plus-circle")]),_c('span',{staticClass:"ml-1"},[_vm._v("新規作成")])],1)])],1)])]),(_vm.toggleSearch)?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('SearchArea',{attrs:{"toggleSearch":_vm.toggleSearch,"searchFields":_vm.searchFields,"selectTypeOptions":_vm.selectTypeOptions},on:{"toggleSearch":function($event){return _vm.setToggleSearch($event)},"searchSubmit":function($event){return _vm.searchSubmit($event)},"changedInputType":function($event){return _vm.setChangedInputType($event)},"resetForm":function($event){return _vm.getDataFromApi()}}})],1):_vm._e(),_c('v-col',{staticClass:"d-flex w-100",attrs:{"cols":"12","md":"12"}},[_c('DataTable',{attrs:{"items":_vm.initialLoad ? [] : _vm.getAllNotifications.news,"headers":_vm.headers,"total-records":_vm.getNotificationsTotalRecord ? _vm.getNotificationsTotalRecord : 0,"number-of-pages":_vm.getNotificationsTotalPages ? _vm.getNotificationsTotalPages : 0,"loading":_vm.loading},on:{"update:options":_vm.updateTable,"click:row":function($event){return _vm.$router.push({
              name: 'NotificationsEdit',
              params: { id: $event.id }
            })}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at && item.created_at != '' ? item.created_at : '---- / - / -','YYYY/MM/DD'))+" ")]}},{key:"item.title",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(item.title)+" ")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }